import React from 'react'

import App from '../components/shared/app'
import Layout from '../components/shared/layout'
import Catalog from '../components/app/blocks/catalog'

const Page = (props) => {
  return (
    <App {...props}>
      <Layout bg=''>
        <div className='push-footer'>
          <div className='top-block'></div>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12 m-auto text-center pt-4 pb-5'>
                <img
                  src='/img/brand/logo.png'
                  className='navbar-brand-img'
                  alt='inmemori.com'
                  width='110px'
                  style={{filter: 'invert(0)'}}
                />
              </div>
              <div className='content col-12 col-lg-10 m-auto px-0'>
                <Catalog />
              </div>
            </div>
          </div>
        </div>

        <style jsx global>
          {`
            .top-block {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              height: 280px;
            }

            .content {
              background: #ffffffeb;
              border-radius: 16px;
            }

            // md down
            @media (max-width: 767px) {
              .content {
                border-radius: 0px;
              }
            }
          `}
        </style>
      </Layout>
    </App>
  )
}

Page.getInitialProps = async (ctx) => await App.getInitialProps(ctx)

export default Page
