import React, {useState, useEffect, useMemo} from 'react'
import {withGlobal} from '../../shared/app'
import {Flux, useFlux} from '../../misc/flux'
import Login from '../../shared/login'

const Page = ({authed, hasRole, t, cloudinary, Profile, Products}) => {
  const [feature, setFeature] = useState('flower')

  useEffect(() => {
    Products.load(true)
    return () => {}
  }, [])

  const catalog = useMemo(() => Products.sorted(feature, 'price_int'), [Products.products, feature])

  return (
    <div className='p-4'>
      <div className='text-center mb-5'>
        <h2>Catalogue des produits</h2>
      </div>

      <div>
        {(!authed || !hasRole('team')) && (
          <div className='splash-left container-fluid d-flex flex-column justify-content-between px-0'>
            <Login className='flex-grow-1' team />
          </div>
        )}
        {authed && hasRole('team') && catalog.length > 0 && (
          <React.Fragment>
            <div className='grid mt-2'>
              {catalog.map((flower, i) => (
                <div className='item pb-3 pt-2 cursor' key={i} onClick={() => {}}>
                  <div className='d-flex flex-column align-items-start'>
                    <div className='item-pic-container'>
                      <img
                        className='img-fluid item-pic'
                        src={cloudinary.url(
                          Products.Flower.imagePathFromCatalog(flower, flower.images[0]),
                          {
                            filter: 'c_fit,h_1000,q_auto:best,w_1000,fl_lossy,f_auto'
                          }
                        )}
                      />
                      {flower.tags && (
                        <div className='item-tag-list d-flex flex-column'>
                          {flower.tags.map((tag) => {
                            return (
                              <div
                                key={`flower-index-${i}-tag-${tag}`}
                                className='d-inline-block text-primary py-1 px-2 mb-1 rounded bg-white'
                                style={{width: 'fit-content'}}>
                                {t(`products.tags.${tag}`)}
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                    <h4 className='item-name f2 text-center mt-2'>{flower.name}</h4>
                    <span>
                      {t('common.startingAt')}{' '}
                      <span className='text-primary font-weight-bold'>{flower.price}</span>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
      </div>
      <style jsx>{`
        .item:hover h4 {
          color: var(--primary);
        }

        .item-pic-container {
          position: relative;
          display: block;
          width: 100%;
          padding-top: calc(100%);
          background-color: #f8f5f3;
          border-radius: 16px;
          overflow: hidden;
        }

        .item-pic {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .item-tag-list {
          position: absolute;
          top: 1em;
          left: 1em;
        }

        .grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 20px;
        }

        @media (max-width: 1800px) {
          // xl down
          .grid {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;
          }
        }

        @media (max-width: 991px) {
          // lg down
          .grid {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 15px;
          }
        }

        @media (max-width: 767px) {
          // md down
          .grid {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
          }
        }

        @media (max-width: 605px) {
          // sm down
          .grid {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
          }
        }
      `}</style>
    </div>
  )
}

export default withGlobal(Page)
